
import { defineComponent } from "vue";
import { elementSize } from "@/utils";
export default defineComponent({
  name: "TableWrapper",
  props: {
    isPagination: {
      type: Boolean,
      default: false
    },
    bottomHeight: {
      type: Number,
      default: 40
    }
  },
  setup() {
    const tableSize = elementSize(".payment-table-wrapper");
    return {
      tableSize,
    };
  },
});
