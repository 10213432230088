
import { defineComponent, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import useTaskDistributionOverview from "@/hooks/rv/communication/useTaskDistributionOverview";
import FormModal from "@/views/RetailValidation/Communication/TaskDistribution/formModal.vue";
import { Modal } from "ant-design-vue";
import { message } from "ant-design-vue";
import { deleteTask } from "@/API/rv/rvSplitTask";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const router = useRouter();
    const {
      query,
      taskPeriod,
      dateFormat,
      column,
      data,
      fetchTableData,
      statusList,
      categoryList,
    } = useTaskDistributionOverview();
    const search = () => {
      fetchTableData();
    };
    const init = () => {
      search();
    };
    init();
    const formModalVisible = ref(false);
    const formType = ref("create");
    const handleAdd = () => {
      formType.value = "create";
      formModalVisible.value = true;
    };
    const curRow = ref({});
    const handleDelete = (record: any) => {
      Modal.confirm({
        title: "Do you confirm to delete current data?",
        okText: "Confirm",
        okType: "primary",
        cancelText: "Cancel",
        onOk() {
          deleteTask({
            id: record.id,
          }).then(() => {
            message.success("Delete successfully");
            search();
          });
        },
      });
    };
    const handleToDetail = (record: any) => {
      router.push({
        path: "/retailValidation/taskDistribution/detail",
        query: {
          status: record.taskStatus,
          category: record.category,
          id: record.id,
          name: record.taskNameCn
        },
      });
    };
    const handleEdit = (record: any) => {
      formType.value = "edit";
      curRow.value = record;
      formModalVisible.value = true;
    };
    return {
      ...toRefs(query),
      taskPeriod,
      dateFormat,
      column,
      data,
      search,
      formModalVisible,
      formType,
      curRow, // 被点击的当前行
      handleAdd,
      handleDelete,
      handleToDetail,
      handleEdit,
      statusList,
      categoryList,
      isPagination: false,
    };
  },
  components: {
    TableWrapper,
    FormModal,
  },
});
