import { ref, reactive, computed, h } from "vue";
import { getTaskOverviwList } from "@/API/rv/rvSplitTask";
import { Moment } from "moment";
import useMock from "@/utils/payment/useMock";
import {
  TaskStatusList,
  TaskCategoryList,
} from "@/views/RetailValidation/Communication/dictionary";
const useTaskDistributionOverview = () => {
  const statusList = ref(TaskStatusList);
  const categoryList = ref(TaskCategoryList);
  const useFormatExpirationDate = () => {
    return ({ text }: { text: number | string }) => {
      const _text = text ? text : "No Request";
      return h("span", _text);
    };
  };
  // 将list转化为map
  const getMap = (list: any[]) => {
    return list?.reduce((prev, cur) => {
      prev[cur.value] = cur.name;
      return prev;
    }, {});
  };
  const column = ref([
    { title: "Task Name CN", dataIndex: "taskNameCn", width: 168 },
    {
      title: "Task Category",
      dataIndex: "category",
      width: 168,
      customRender: ({ text }: any) => {
        return h("span", getMap(categoryList.value)[text]);
      },
    },
    { title: "Release Time", dataIndex: "releaseTime", width: 168 },
    {
      title: "Expiration Date",
      dataIndex: "expirationDate",
      width: 168,
      customRender: useFormatExpirationDate(),
    },
    {
      title: "Task Status",
      dataIndex: "taskStatus",
      width: 168,
      customRender: ({ text }: any) => {
        return h("span", getMap(statusList.value)[text]);
      },
    },
    { title: "Dealer No.", dataIndex: "dealerNum", width: 168 },
    {
      title: "Operation",
      width: 150,
      slots: { customRender: "operation" },
      align: "center",
    },
  ]);
  const taskPeriod = ref<Moment[]>([]);
  const dateFormat = ref("YYYY/MM/DD");
  const query = reactive({
    taskStatus: "-1",
    taskNameCn: "",
    category: "-1",
  });
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value ? dataRes.value : [];
  });
  const fetchTableData = () => {
    const params = {
      isTimeData: "-1",
      beginDate: "",
      endDate: "",
      taskStatus: query.taskStatus,
      taskNameCn: query.taskNameCn,
      category: query.category,
    };
    if (taskPeriod.value.length) {
      params.isTimeData = "1";
      params.beginDate = taskPeriod.value[0].format(dateFormat.value);
      params.endDate = taskPeriod.value[1].format(dateFormat.value);
    }
    getTaskOverviwList(params).then((res: any) => {
      dataRes.value = res;
    });
  };
  return {
    query,
    taskPeriod,
    dateFormat,
    column,
    data,
    fetchTableData,
    statusList,
    categoryList,
  };
};
export default useTaskDistributionOverview;
