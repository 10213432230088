import { fetchGet, fetchPost, fetchPostPic } from "../index";
import type { FileResponse } from '@/API/types'
import axios from 'axios'

export const getTaskOverviwList = (params = {}): Promise<any> => {
  return fetchPost("/rvapi/task/overview/list", params);
};
export const addTask = (params = {}) => {
  return fetchPost("/rvapi/task/overview/add", params);
};
export const editTask = (params = {}) => {
  return fetchPost("/rvapi/task/overview/edit", params);
};
export const deleteTask = (params = {}) => {
  return fetchPost("/rvapi/task/overview/delete", params);
};
export const getTaskDetailList = (params = {}) => {
  return fetchPost("/rvapi/task/detail/list", params);
};
export const getTaskDetailView = (id: any) => {
  return fetchPost(`/rvapi/task/detail/view?id=${id}`);
};
export const reuploadFile = (id: any) => {
  return fetchPost(`/rvapi/task/docData/reUpload/data?headerId=${id}`);
};
export const reuploadDocFile = (id: any) => {
  return fetchPost(`/rvapi/task/docData/reUpload/doc?headerId=${id}`);
};
export const checkMappingFile = (mappingFile: Blob, config = {}) => {
  const formData = new FormData()
  formData.set('mapping', mappingFile)
  return fetchPost('/rvapi/task/docData/upload/mapping/standard', formData, config)
}
export const checkDocData = (id: string, mappingFile: Blob, documentFiles: Blob[], config = {}): Promise<any> => {
  const formData = new FormData()
  formData.set('mapping', mappingFile)
  documentFiles.forEach(documentFile => {
    formData.append('documents', documentFile)
  })

  return fetchPostPic('/rvapi/task/docData/upload/doc/errorData', formData, {
    params: {
      headerId: id
    },
    ...config
  });
};
export const uploadDocData = (id: string, mappingFile: Blob, documentFiles: Blob[], config = {}) => {
  const formData = new FormData()
  formData.set('mapping', mappingFile)
  documentFiles.forEach(documentFile => {
    formData.append('documents', documentFile)
  })

  return fetchPostPic('/rvapi/task/docData/upload/mapping', formData, {
    params: {
      headerId: id
    },
    ...config
  });
}
export const docIsSplit = (mappingFile: Blob): Promise<FileResponse> => {
  const formData = new FormData();
  formData.set('document', mappingFile);
  return fetchPostPic('/rvapi/task/docData/upload/doc/isSplit', formData);
}
export const getDocSplitNum = (id: string, mappingFile: Blob) => {
  const formData = new FormData();
  formData.set('mapping', mappingFile);
  return fetchPost('/rvapi/task/docData/upload/mapping/dealerNum', formData, {
    params: {
      headerId: id
    }
  });
}
// 状态waitting for release
export const taskWait = (id: any) => {
  return fetchPost(`/rvapi/task/overview/wait?headerId=${id}`);
};
// 状态release
export const taskRelease = (id: any) => {
  return fetchGet(`/rvapi/task/overview/release`, {
    params: {
      headerId: id
    }
  });
};
// OASIS-16731 Task Overview_Region
export const getTaskOverviewRegionList = (params = {}): Promise<any> => {
  return fetchPost('/rvapi/task/overview/listRegion', params);
};

export const getTaskOverviewRegionDetail = (params = {}): Promise<any> => {
  return fetchPost('/rvapi/task/detail/region', params);
};

export const dealerTaskList = (params = {}): Promise<any> => {
  return fetchPost('/rvapi/task/dealer/list', params);
};

export const dealerViewFeedback = (id: number) => {
  return fetchPost('/rvapi/task/dealer/view/feedBack', {}, {
    params: {
      id
    }
  });
};

export const dealerAddFeedback = (id: number, feedback: string) => {
  return fetchPost('/rvapi/task/dealer/add/feedBack', {}, {
    params: {
      id,
      feedback
    }
  });
};

export const dealerEditFeedback = (id: number, feedback: string) => {
  return fetchPost('/rvapi/task/dealer/edit/feedBack', {}, {
    params: {
      id,
      feedback
    }
  });
};

export const dealerDocExport = (detailId: number): Promise<{downloadUrl: string}> => {
  return fetchPost('/rvapi/task/docData/download/dealerDoc', {}, {
    params: {
      detailId
    }
  })
}

export const dealerDataExport = (headerId: number, dealerCode: string) => {
  return axios.post('/rvapi/task/docData/download/dealer', null, {
    responseType: "blob",
    params: {
      headerId,
      dealerCode
    }
  })
}

export const dataTaskDetailExport = (params = {}) => {
  return axios.post('/rvapi/task/docData/download/data/taskDetail', params, {
    responseType: "blob"
  })
}

export const exportDealerListQueryResult = (params = {}) => {
  return axios.post('/rvapi/task/docData/download/dealerAll', params, {
    responseType: "blob"
  })
}

export const downloadDocuments = (params = {}) => {
  return fetchPost('/rvapi/task/detail/download/documents', params)
}