
import { ref, defineComponent, computed, reactive, watch, toRaw } from "vue";
import useModel from "@/hooks/useModel";
import moment from "moment";
import { Moment } from "moment";
import { message } from "ant-design-vue";
import { TaskCategoryList } from "@/views/RetailValidation/Communication/dictionary";
import { addTask, editTask } from "@/API/rv/rvSplitTask";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    formType: {
      type: String,
      default: "create",
    },
    curRow: {
      type: Object,
    },
    dateFormat: {
      type: String,
    },
  },
  setup(props, context) {
    const _visible = useModel(props, "visible");
    const title = computed(() => {
      return props.formType === "create" ? "Create Task" : "Edit Task";
    });
    const categoryList = computed(() => {
      return TaskCategoryList.filter((item: any) => {
        return item.name !== "All";
      });
    });
    const form = reactive({
      id: "",
      taskNameCn: "",
      category: undefined,
      expirationDate: "",
      isDealerFeedback: "1",
      isSendToRegion: "1",
    });
    const rules = reactive({
      taskNameCn: [
        {
          required: true,
          message: "Please input task name",
          trigger: "blur",
        },
        {
          min: 1,
          max: 25,
          message: "Length should not be more than 25",
          trigger: "blur",
        },
      ],
      category: [
        {
          required: true,
          message: "Please input task category",
          trigger: "change",
        },
      ],
      expirationDate: [
        {
          required: true,
          type: "date",
          message: "Please select expiration date",
          trigger: "change",
        },
      ],
    });
    const handleCancel = () => {
      _visible.value = false;
      form.taskNameCn = "";
      form.category = undefined;
      form.isDealerFeedback = "1";
      form.isSendToRegion = "1";
      form.expirationDate = "";
    };
    const formRef = ref();
    // 编辑
    const handleEdit = () => {
      const params = {
        id: form.id,
        taskNameCn: form.taskNameCn,
      };
      return editTask(params);
    };
    // 新增
    const handleCreate = () => {
      const params = { ...toRaw(form) };
      return addTask(params);
    };
    const loading = ref(false);
    const closeModal = () => {
      loading.value = false;
      handleCancel();
      message.success("Save successfully");
      context.emit("on-confirm");
    };
    // 保存 新增和编辑
    const handleSave = () => {
      formRef.value.validate().then(() => {
        loading.value = true;
        if (props.formType === "create") {
          handleCreate().then(() => {
            closeModal();
          });
        } else {
          handleEdit().then(() => {
            closeModal();
          });
        }
      });
    };

    watch(
      () => props.visible,
      (visible) => {
        if (visible && props.formType === "edit") {
          if (props.curRow) {
            form.id = props.curRow.id;
            form.taskNameCn = props.curRow.taskNameCn;
            form.category = props.curRow.category;
            form.expirationDate = props.curRow.expirationDate;
            form.isDealerFeedback = props.curRow.isFeedback ? "1" : "0";
            form.isSendToRegion = props.curRow.isSendRegion ? "1" : "0";
          }
        }
      }
    );
    return {
      _visible,
      title,
      formRef,
      form,
      rules,
      handleCancel,
      handleSave,
      loading,
      categoryList,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
  },
});
