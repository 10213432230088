import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["payment-table-wrapper", {'pagination':_ctx.isPagination}])
  }, [
    _renderSlot(_ctx.$slots, "default", {
      tableHeight: _ctx.tableSize.height - (_ctx.isPagination ? _ctx.bottomHeight + 40 : _ctx.bottomHeight)
    }, undefined, true)
  ], 2))
}