export const TaskStatusList = [
  {
    name: "Draft",
    value: "1",
  },
  {
    name: "Waiting for release",
    value: "2",
  },
  {
    name: "Released",
    value: "3",
  },
  {
    name: "All",
    value: "-1",
  },
];
export const TaskCategoryList = [
  {
    name: "Data",
    value: "1",
  },
  {
    name: "Document",
    value: "2",
  },
  {
    name: "All",
    value: "-1",
  }
];
export const TaskCategoryListCn = [
  {
    name: "数据类别",
    value: "1",
  },
  {
    name: "文件类别",
    value: "2",
  },
  {
    name: "全部",
    value: "-1",
  }
];
